import { appName, publicAccess } from "@/services/config/configService";

// utils
import { isEmpty } from "@/utils";

// mixins
import { appMixin } from "@/mixins/shared/base/app/appMixin";
import { reactiveMixin } from "@/mixins/shared/reactive/reactiveMixin";
import { appBarSettingsMixin } from "@/mixins/shared/base/settings/appBarSettingsMixin";
import { userSettingsMixin } from "@/mixins/shared/base/settings/userSettingsMixin";
import { publicAccessMixin } from "@/mixins/public/publicAccessMixin";

export const appBarMixin = {
  mixins: [
    appMixin,
    reactiveMixin,
    appBarSettingsMixin,
    userSettingsMixin,
    publicAccessMixin
  ],
  data() {
    return {
      collapse: false,
      messages: 0,
      /**
       *Designates that the appModule's v-navigation-drawerLeft that is positioned on the right is below the app-bar.
       */
      clippedRight: true
    };
  },
  props: {
    userName: {
      type: String,
      default: "User"
    },
    userToolTip: {
      type: String,
      default: "Current User"
    }
  },
  computed: {
    dark() {
      return !isEmpty(this.appBarSettingColorClass);
    },

    /**
     * App Bar tile
     * @return {string} App Bar tile
     */
    title() {
      return this.isPublicAccess
        ? publicAccess?.appBar?.title ?? appName
        : appName;
    },

    /**
     * determine app bar title visibility
     * @return {boolean}
     */
    visibleTitle() {
      return !!this.title; // !!this.isPublicAccess;
    },

    /**
     * determine Avatar visibility
     * @return {boolean}
     */
    visibleAvatar() {
      return !!this.avatarImgSrc && this.visibleOnMdAndUp;
    },

    /**
     * avatar Size
     * @return {string}
     */
    avatarSize() {
      if (this.isPublicAccess) {
        const size = this.publicAccess?.appBar?.logoSize ?? 0;
        if (size > 0) {
          return `${size}px`;
        }
      }
      return "48px"; // default
    },

    /**
     * avatar Image source
     * @return {string|undefined}
     */
    avatarImgSrc() {
      if (this.isPublicAccess) {
        try {
          return this.publicAccess?.appBar?.logo;
          // or:
          // const iconName = this.publicAccess?.appBar?.logo;
          // // return require(`/public/Images/questys-logo.svg`); // ok
          // if (!iconName) {
          //   return undefined;
          // }
          // return require(`/public/Images/${iconName}`);
        } catch (e) {
          //console.warn(`Couldn't find file: '${iconName}' in: 'Images/'`);
          return undefined;
        }
      }

      try {
        return require(`@/assets/icons/questys-logo.svg`);
      } catch (e) {
        console.warn(`Couldn't find file: @/assets/icons/questys-logo.svg`);
        return undefined;
      }
    },

    /**
     * avatar Style, which set margin-right
     * @return {string}
     */
    avatarStyle() {
      const margin = 8;
      // return {
      //   "margin-right": `${margin}px;` // does not work
      //   // ["margin-right"]: `${margin}px;` // does not work
      // };
      return `margin-right: ${margin}px;`;
    },

    /**
     * app Bar Background Image Src
     * @return {string|undefined}
     */
    appBarBackgroundImageSrc() {
      return this.isPublicAccess
        ? this.appBarSettingBackgroundImageUrl
        : undefined;
    }
  }
};
