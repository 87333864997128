// design
import { Color } from "@/design/colors/Color";

// vuex/store
import { createNamespacedHelpers } from "vuex";
const { mapGetters } = createNamespacedHelpers("solution");

// mixins
import { publicAccessMixin } from "@/mixins/public/publicAccessMixin";
import { isEmpty } from "@/utils";

/**
 * Application Bar Settings Mixin encapsulate Settings functionality of App Bar
 */
export const appBarSettingsMixin = {
  mixins: [publicAccessMixin],
  computed: {
    ...mapGetters({
      appBarColor: "appBarColor",
      appBarGradient: "appBarGradient",
      appBarCollapseOnScroll: "appBarCollapseOnScroll",
      appBarUsesAppTabs: "usesAppTabs"
    }),
    /**
     * Get AppBar Toolbar Material Design color
     * @returns {Color}
     */
    mdAppBarColor() {
      return new Color(
        this.appBarColor.name,
        this.appBarColor.variantType,
        this.appBarColor.variantNumber
      );
    },

    /**
     * AppBar color
     * Applies specified color to the control
     * Note: it can be the name of material color (for example success or purple)
     * or css color (#033 or rgba(255, 0, 0, 0.5))
     * @returns {string}
     */
    appBarSettingColorClass() {
      return this.isPublicAccess
        ? this.publicAccess?.appBar?.color ?? this.mdAppBarColor.getClassColor()
        : this.mdAppBarColor.getClassColor();
    },

    /**
     * app Bar gradient is used to apply a simple gradient overlay to the appBar background image
     * @return {string} app Bar gradient
     */
    appBarSettingGradient() {
      return this.isPublicAccess
        ? this.publicAccess?.appBar?.gradient
        : this.appBarGradient;
    },

    /**
     * app Bar Setting Collapse On Scroll
     * @return {boolean} true if App Bar collapse On Scroll
     */
    appBarSettingCollapseOnScroll() {
      return this.isPublicAccess
        ? this.publicAccess?.appBar?.collapseOnScroll
        : this.appBarCollapseOnScroll;
    },

    /**
     * app Bar Setting Uses App Tabs
     * @return {boolean|boolean} true if app Bar Setting Uses App Tabs
     */
    appBarSettingUsesAppTabs() {
      return this.isPublicAccess
        ? this.publicAccess?.appBar?.useTabs ?? true
        : this.appBarUsesAppTabs;
    },

    /**
     * Visible Application Navigation
     * @return {string}
     */
    visibleApplicationNavigation() {
      return this.isPublicAccess
        ? this.publicAccess.modules.visibleFolder ||
            this.publicAccess.modules.visibleCategory ||
            this.publicAccess.modules.visibleMeetings
        : true;
    },

    /**
     * compute app Bar background Image URL
     * @return {string|undefined} app Bar background Image URL
     */
    appBarSettingBackgroundImageUrl() {
      return this.isPublicAccess
        ? this.publicAccess?.appBar?.backgroundImageUrl
        : require("@/assets/icons/questys-logo-hz.svg");
    },

    /**
     * app Bar Avatar Url
     * @return {String|string|undefined}
     */
    appBarSettingAvatarUrl() {
      return this.isPublicAccess
        ? this.publicAccess?.appBar?.logo
        : "@/assets/icons/questys-logo.svg";
    },

    /**
     * Increases the height of the toolbar content to 128px.
     * @return {boolean} if true Increases the height of the toolbar content to 128px.
     */
    appBarSettingProminent() {
      return this.isPublicAccess
        ? this.publicAccess?.appBar?.prominent ?? true
        : false;
    },

    /**
     * When using the src prop or img slot, will fade the image when scrolling
     * @return {boolean} if true then When using the src prop or img slot, will fade the image when scrolling
     */
    appBarSettingFadeImageOnScroll() {
      return this.isPublicAccess
        ? this.publicAccess?.appBar?.fadeImgOnScroll ?? false
        : false;
    },

    /**
     * Shrinks a prominent toolbar to a dense or short (default) one when scrolling.
     * @return {boolean} if true then Shrinks a prominent toolbar to a dense or short (default) one when scrolling.
     */
    appBarSettingShrinkOnScroll() {
      return this.isPublicAccess
        ? this.publicAccess?.appBar?.shrinkOnScroll ?? false
        : false;
    },

    /**
     * app-Bar Setting Visible Avatar
     * @return {boolean|boolean} true if app-Bar Setting Visible Avatar
     */
    appBarSettingVisibleAvatar() {
      return this.isPublicAccess
        ? false
        : !isEmpty(this.appBarSettingAvatarUrl); // isEmpty(this.appBarSettingBackgroundImageUrl);
    }
  }
};
